import { useEffect, useState, useMemo, useRef } from "react"
import { addPropertyControls, ControlType } from "framer"
import { useInView } from "react-hook-inview"
import { useCountUp } from "use-count-up"
import { CircularProgressbar } from "react-circular-progressbar"
import styled from "styled-components"

const Gradient = styled.div`
.grad {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
`

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function YoutubeCounter(props) {
    const {
        type,
        font,
        replay,
        delay,
        start,
        duration,
        easing,
        decimalPlaces,
        thousandsSeparator,
        decimalSeparator,
        playOnView,
        suffix,
        prefix,
        linear,
        linearOptions,
        color,
        apiKey,
        channelID,
        videoShift,
    } = props

    // Store the end value in state
    const [end, setEnd] = useState(0)

    const url = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelID}&key=${apiKey}`
    // Fetch the total number of uploaded content to the @framer.university YouTube channel
    useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const totalVideos = parseInt(
                    data.items[0].statistics.videoCount
                )
                // Update the end value with the desired value
                setEnd(totalVideos + videoShift)
            })
            .then((data) => {
                console.log(data)
            })
            .catch((error) => console.error(error))
    }, [])

    const [isCounting, setIsCounting] = useState(playOnView ? false : true)
    const [counterRef, inView] = useInView({ threshold: 1 })

    const setValue = (func) => (event) => func(parseFloat(event.target.value))
    const setText = (func) => (event) => func(event.target.value)
    const onComplete = () => ({ shouldRepeat: replay, delay })

    let colorVal = color

    useEffect(() => {
        colorVal = color
    }, [color])

    const stylingWithSolid = {
        fontSize: font.size,
        fontFamily: font.family,
        color: colorVal,
        fontWeight: font.weight,
        fontStyle: font.style,
    }

    const stylingWithLinear = {
        fontSize: font.size,
        fontFamily: font.family,
        color: "transparent",
        backgroundImage: colorVal,
        fontWeight: font.weight,
        fontStyle: font.style,
    }

    const { value, reset } = useCountUp({
        isCounting,
        start,
        end,
        duration,
        easing,
        decimalPlaces,
        thousandsSeparator,
        decimalSeparator,
        onComplete,
    })

    useEffect(() => {
        if (inView) {
            reset()
            setIsCounting(true)
        }
    }, [inView])

    return linear ? (
        <Gradient>
            <div ref={counterRef} style={stylingWithLinear} className="grad">
                {prefix}
                {value}
                {suffix}
            </div>
        </Gradient>
    ) : (
        <div ref={counterRef} style={stylingWithSolid}>
            {prefix}
            {value}
            {suffix}
        </div>
    )
}

addPropertyControls(YoutubeCounter, {
    videoShift: {
        type: ControlType.Number,
        defaultValue: 0,
    },
    channelID: {
        type: ControlType.String,
        defaultValue: "",
    },
    apiKey: {
        type: ControlType.String,
        defaultValue: "",
    },
    start: {
        type: ControlType.Number,
        defaultValue: 0,
        title: "Start from",
    },
    suffix: {
        type: ControlType.String,
        defaultValue: "",
    },
    prefix: {
        type: ControlType.String,
        defaultValue: "",
    },
    duration: {
        type: ControlType.Number,
        defaultValue: 10,
        unit: "s",
    },
    playOnView: {
        type: ControlType.Boolean,
        defaultValue: false,
        description: "Defaults to playing on render",
    },
    easing: {
        type: ControlType.Enum,
        options: ["easeOutCubic", "easeInCubic", "linear"],
    },
    replay: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    delay: {
        type: ControlType.Number,
        defaultValue: 2,
        hidden(props) {
            return props.replay != true
        },
        unit: "s",
    },
    decimalPlaces: {
        type: ControlType.Number,
        defaultValue: 0,
        description:
            "Number of decimal places after the decimal separator. Defaults to the max decimal places count from start and end props",
    },
    thousandsSeparator: { type: ControlType.String, defaultValue: "," },
    decimalSeparator: { type: ControlType.String, defaultValue: "." },
    linear: { type: ControlType.Boolean, defaultValue: false },
    linearOptions: {
        type: ControlType.Object,
        controls: {
            toColor: { type: ControlType.Color, defaultValue: "red" },
            opacityTo: {
                type: ControlType.Number,
                defaultValue: 100,
                min: 0,
                max: 100,
            },
            fromColor: { type: ControlType.Color, defaultValue: "green" },
            opacityFrom: {
                type: ControlType.Number,
                defaultValue: 100,
                min: 0,
                max: 100,
            },
            degree: { type: ControlType.Number, defaultValue: 0, max: 360 },
        },
        hidden(props) {
            return !props.linear
        },
        description: "Only works if linear enabled.",
    },
    color: {
        type: ControlType.Color,
        defaultValue: "black",
        hidden(props) {
            return props.linear
        },
    },
    font: {
        type: ControlType.Object,
        controls: {
            family: {
                type: ControlType.String,
                defaultValue: "Inter",
            },
            size: { type: ControlType.Number, defaultValue: 30 },
            style: {
                type: ControlType.Enum,
                options: ["normal", "italic"],
            },
            weight: {
                type: ControlType.Enum,
                defaultValue: 600,
                options: [100, 200, 300, 400, 500, 600, 700, 800, 900],
                description: "Ensure that the font supports the chosen weight",
            },
        },
    },
})
